import React from 'react'
import PropTypes from 'prop-types'
import {Link} from 'gatsby'
import NewsPreview from './NewsPreview'
import Navbar from './Navbar'
import Content from './Content'
import Footer from './Footer'
import Helmet from 'react-helmet'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import CustomForm from './CustomForm'
import "typeface-playfair-display"
import "typeface-roboto"
import "./all.sass"

import ImageBarFull from './ImageBarFull'
  
const HomePageTemplate = ({
  content,
  contentComponent,
}) => {
  const PostContent = contentComponent || Content

return (
  < >
    <Helmet title="Montgomery County MUD No. 42" />
    <Helmet>
      <meta name="google-site-verification" content="Qoxj4Hlzxo9ST0EsOtRgfo8wnSStUJIgr_Bo3HEibeI" />
    </Helmet>
    <div className="">
    <Navbar />
    <ImageBarFull />
    <section className="section__home-page">
      <div className="container container__homepage">
        <h2 className="news-preview-grid-title">What's New</h2> <div className="news-section news-full-grid">
          <NewsPreview />
          <Link to="/latest-updates" className="updates">
              See All<br />
              Latest Updates<br />
              <div className="arrow"></div>
          </Link>

          <div className="eNews">
                <h3 className="is-size-2" style={{ marginBottom: '1rem'}}>Sign up to receive updates straight to your inbox!</h3>
                <MailchimpSubscribe
                  url="https://touchstonedistrictservices.us19.list-manage.com/subscribe/post?u=9e9660a8cc948aa82bf3acc40&amp;id=3f84eabb38"
                  render={({ subscribe, status, message }) => (
                    <CustomForm
                      status={status}
                      message={message}
                      onValidated={formData => subscribe(formData)}
                    />
                  )}
                />
          </div>
        </div>
      </div>
    </section>
    </div>
    <section className="section">
      <div className="container">
      <div className="columns">
        <div className="column is-10 is-offset-1 ">
          <div className="content homepage-content">
            <PostContent content={content} />
          </div>
        </div>
        <div className="column is-3 is-offset-1 vertical-justify">
          {/* <Image className="asideImage" fixed={welcomeAside.childImageSharp.fixed} alt="" /> */}
        </div>
      </div>
      </div>
    </section>
    <Footer />
  </ >
)}

HomePageTemplate.propTypes = {
  title: PropTypes.string,
  welcomeMessage: PropTypes.string,
}

export default HomePageTemplate
